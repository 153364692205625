<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Sellingchart from "./sellingchart";

import simplebar from "simplebar-vue";
import { required } from "vuelidate/lib/validators";


export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    /* eslint-disable vue/no-unused-components */
    simplebar,
    Layout,
    //PlayerCountries: () => import('@/components/lazy/dashboard/PlayerCountries'),
    ServersShortlist: () => import('@/components/lazy/dashboard/ServersShortlist'),
    AccountInfo: () => import('@/components/lazy/dashboard/AccountInfo'),

  },
  data() {
    return {

    };
  },
  validations: {

  },
  methods: {

  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">
        Dashboard
      </h4>
      <div class="page-title-right bv-d-xs-down-none"><ol class="breadcrumb m-0">
        <li class="breadcrumb-item">
          <router-link to="/">
            CFTools Cloud
          </router-link>
        </li>
      </ol>
      </div>
    </div>
    <AccountInfo></AccountInfo>
    <div class="row">
      <ServersShortlist></ServersShortlist>
      <!-- <PlayerCountries></PlayerCountries> -->
    </div>
    <!-- end row -->
  </Layout>
</template>